@charset "utf-8";

.header {
    @include mq() {
        display: flex;
        flex-direction: column-reverse;
    }
}

.header-inner {
    position: relative;
    padding: .17rem 0 .24rem;
    @include mq() {
        display: flex;
        margin: auto;
        padding: 0 0 0 30px;
        max-width: 1200px;
        width: 100%;
    }
}

.header-logo {
    margin: auto;
    width: 1.25rem;
    @include mq() {
        flex-shrink: 0;
        position: relative;
        margin: 0;
        width: auto;
        h1 {
            position: relative;
            z-index: 2;
            margin-top: -10px;
        }
        &::before {
            content: '';
            display: block;
            position: absolute;
            z-index: 1;
            bottom: 100%;
            left: 50%;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 0 105px 64px 105px;
            border-color: transparent transparent #ffffff transparent;
            transform: translateX(-50%);
        }
    }
}


.header-nav-spMenuTrigger {
    position: absolute;
    top: 50%;
    right: .3rem;
    z-index: 2;
    width: .44rem;
    height: .38rem;
    overflow: hidden;
    transform: translateY(-50%);
    @include mq() {
        display: none;
    }
    span {
        position: absolute;
        display: block;
        width: 100%;
        height: .04rem;
        background: $darkred;
        transition: .6s;
        &:nth-child(1) {
            top: 0;
        }
        &:nth-child(2) {
            top: 50%;
            transform: translate(0,-50%);
        ;}
        &:nth-child(3) {
            bottom: 0;
        }
    }
    &.active {
        span {
            &:nth-child(1) {
                top: 50%;
                transform: translateY(-50%) rotate(225deg);
            }
            &:nth-child(2) {
                transform: translate(100%,-50%);
                opacity: 0;
            }
            &:nth-child(3) {
                top: 50%;
                transform: translateY(-50%) rotate(-225deg);
            }
        }
    }
}

.header-nav {
    @include mq() {
        max-width: 730px;
        width: 100%;
        margin: auto;
    }
}

.header-nav-list {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 10;
    padding:.9rem 0 1.1rem .6rem;
    width: 100%;
    background: $darkred;
    @include mq() {
        position: static;
        display: flex!important;
        justify-content: space-between;
        padding: 50px 0 30px;
        background: transparent;
    }
    li {
        transform: translateX(-20%);
        opacity: 0;
        transition: .4s;
        @include mq() {
            margin: 0 5px;
            opacity: 1;
            transform: translateX(0);
        }
        + li {
            margin-top: .48rem;
            @include mq() {
                margin-top: 0;
            }
        }
    }
    &.active {
        li {
            opacity: 1;
            transform: translateX(0);
            @for $i from 1 through 7 {
              &:nth-child(#{$i}) {
                  transition-delay: #{$i * .15 - .15}s;
              }
            }
        }
    }
    a {
        font-size: .48rem;
        letter-spacing: .06em;
        font-weight: 700;
        font-family: 'Montserrat', sans-serif;
        color: #fff;
        @include mq() {
            font-size: 15px;
            color: #000;
        }
    }
}

.header-mainvisual {
    @include mq() {
        height: 540px;
        background: url(../img/pc/main_visual.jpg) no-repeat center center;
    }
}
