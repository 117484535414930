/*
basestyle
*/

*,
*::before,
*::after {
    box-sizing: border-box;
}

html {
    min-height: 100%;
    @include mqsp() {
        font-size: 13.333vw;
    }
}

body {
    height: 100%;
    min-width: 100%;
    color: #000;
    font-family: $sans-serif-yugothic;
    font-size: 0.26rem;
    font-weight: 400;
    -webkit-text-size-adjust: 100%;
    @include mq() {
        font-size: 16px;
    }
    @media all and (-ms-high-contrast:none) {
        font-family: $sans-serif;
    }

    /* フォントレンダリング設定: 1x解像度ではsubpixel、2x以上の解像度ではgrayscale */
    -webkit-font-smoothing: subpixel-antialiased;
    -moz-osx-font-smoothing: unset;

    @media only screen and (-webkit-min-device-pixel-ratio: 2),
    (min-resolution: 2dppx) {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
}

a {
    color: #000;
    text-decoration: none;
    &:hover {}
}

img {
    border: none;
    vertical-align: top;
    max-width: 100%;
    height: auto;
}

svg {
    max-width: 1000px;
}

address {
    font-style: normal;
}

ul {
    list-style-type: none;
}
