@import url('https://fonts.googleapis.com/css?family=Montserrat:400,700|Staatliches&display=swap');


$sans-serif :-apple-system,BlinkMacSystemFont,'Hiragino Kaku Gothic ProN','ヒラギノ角ゴ ProN W3','メイリオ',sans-serif;
$sans-serif-yugothic :-apple-system,BlinkMacSystemFont,'游ゴシック Medium', YuGothic, 'Yu Gothic Medium','Hiragino Kaku Gothic ProN','ヒラギノ角ゴ ProN W3','メイリオ',sans-serif;
$sans-serif2 :'Fjalla One',-apple-system,BlinkMacSystemFont,'Hiragino Kaku Gothic ProN','ヒラギノ角ゴ ProN W3','メイリオ',sans-serif;
$sans-serif-rounded : 'Lucida Grande', 'segoe UI','ヒラギノ丸ゴ Pro W4','ヒラギノ丸ゴ Pro','Hiragino Maru Gothic Pro','ヒラギノ角ゴ Pro W3','Hiragino Kaku Gothic Pro','HG丸ｺﾞｼｯｸM-PRO','HGMaruGothicMPRO',sans-serif;
$serif :'Noto Serif JP', 游明朝,'Yu Mincho',YuMincho,'Hiragino Mincho ProN',HGS明朝E,メイリオ,Meiryo,serif;

$red:#e22b19;
$darkred:#831a28;
