@mixin center {
text-align: center;
position: relative;
top: 50%;
-webkit-transform: translateY(-50%);
-ms-transform: translateY(-50%);
transform: translateY(-50%);
}

@mixin link-color($color) {
color: $color;

&:hover,
&:active,
&:focus {
color: lighten($color, 20%);
}
}

@mixin opacity($opacity) {
opacity: $opacity;
$ieopacity: ($opacity * 100);
filter: alpha(opacity=$ieopacity);
}

@mixin box-shadow($horizontal: 10px, $vertical: 10px, $blur: 0px, $shadow: 0px, $color: #000000){
  -webkit-box-shadow: $horizontal $vertical $blur $shadow $color;
  -moz-box-shadow: $horizontal $vertical $blur $shadow $color;
  box-shadow: $horizontal $vertical $blur $shadow $color;
}

@mixin trianglearrow($direction: right, $size: 12, $color: $gray){
$set: left;

@if $direction == left{
$set: right;
}@elseif $direction == top{
$set: bottom;
}@elseif $direction == bottom{
$set: top;
}@else{
$set: left;
}
$size: round($size / 2);

width: 0;
height: 0;
border: $size + px solid transparent;
border-#{$set}-color: $color;
}

@mixin arrow($size, $border-width, $border-style, $border-color, $direction) {
content: "";
display: inline-block;
position: absolute;
top: 50%;
width: $size;
height: $size;
margin-top: - ($size / 2);
border: $border-width $border-style $border-color;
@if $direction == up {
-webkit-transform: rotate(-135) + deg;
-moz-transform: rotate(-135) + deg;
transform: rotate(-135) + deg;
}
@else if $direction == under {
-webkit-transform: rotate(45) + deg;
-moz-transform: rotate(45) + deg;
transform: rotate(45) + deg;
}
@else if $direction == right {
-webkit-transform: rotate(-45) + deg;
-moz-transform: rotate(-45) + deg;
transform: rotate(-45) + deg;
}
@else if $direction == left {
-webkit-transform: rotate(135) + deg;
-moz-transform: rotate(135) + deg;
transform: rotate(135) + deg;
}
@else {
-webkit-transform: rotate($direction) + deg;
-moz-transform: rotate($direction) + deg;
transform: rotate($direction) + deg;
}
}

@mixin gradient($start-color, $end-color, $orientation)
 {
 background: $start-color;
 @if $orientation == vertical
 {
 // vertical
 background: -moz-linear-gradient(top,  $start-color 0%, $end-color 100%);
 background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,$start-color), color-stop(100%,$end-color));
 background: -webkit-linear-gradient(top,  $start-color 0%,$end-color 100%);
 background: -o-linear-gradient(top,  $start-color 0%,$end-color 100%);
 background: -ms-linear-gradient(top,  $start-color 0%,$end-color 100%);
 background: linear-gradient(to bottom,  $start-color 0%,$end-color 100%);
 filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$start-color', endColorstr='$end-color',GradientType=0 );
 }
 @else if $orientation == horizontal
 {
 // horizontal
 background: -moz-linear-gradient(left,  $start-color 0%, $end-color 100%);
 background: -webkit-gradient(linear, left top, right top, color-stop(0%,$start-color), color-stop(100%,$end-color));
 background: -webkit-linear-gradient(left,  $start-color 0%,$end-color 100%);
 background: -o-linear-gradient(left,  $start-color 0%,$end-color 100%);
 background: -ms-linear-gradient(left,  $start-color 0%,$end-color 100%);
 background: linear-gradient(to right,  $start-color 0%,$end-color 100%);
 filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$start-color', endColorstr='$end-color',GradientType=1 );
 }
 @else
 {
 // radial
 background: -moz-radial-gradient(center, ellipse cover,  $start-color 0%, $end-color 100%);
 background: -webkit-gradient(radial, center center, 0px, center center, 100%, color-stop(0%,$start-color), color-stop(100%,$end-color));
 background: -webkit-radial-gradient(center, ellipse cover,  $start-color 0%,$end-color 100%);
 background: -o-radial-gradient(center, ellipse cover,  $start-color 0%,$end-color 100%);
 background: -ms-radial-gradient(center, ellipse cover,  $start-color 0%,$end-color 100%);
 background: radial-gradient(ellipse at center,  $start-color 0%,$end-color 100%);
 filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$start-color', endColorstr='$end-color',GradientType=1 );
 }
 }

@mixin gradient-text($angle: 45deg, $color: #f82, $amount: 35%) {
color: $color;
background: -webkit-linear-gradient($angle, $color, adjust-hue($color, $amount));
-webkit-text-fill-color: transparent;
-webkit-background-clip: text;
display: inline-block;
}

@mixin truncate($width: 100%) {
width: $width;
max-width: 100%;
display: block;
overflow: hidden;
white-space: nowrap;
text-overflow: ellipsis;
}

@mixin ghost-button($font, $font-size, $font-color, $border-size, $border-color, $padding, $transition-speed, $hover-color)
 {
 display:inline-block;
 text-decoration:none;
 text-transform:uppercase;
 font-family: $font;
 font-size: $font-size;
 color:$font-color;
 border:$border-size solid $border-color;
 padding:$padding;
 -webkit-transition: color $transition-speed, background $transition-speed;
 transition: color $transition-speed, background $transition-speed;
 &:hover
 {
 background:$border-color;
 color:$hover-color;
 }
 }

@mixin circle($size) {
width: $size;
height: $size;
border-radius: 100%;
}

@mixin absolute($top:null, $right:null, $bottom:null, $left:null){
  position: absolute;
  @if $top != null{
    top: $top;
  }
  @if $right != null{
    right: $right;
  }
  @if $bottom != null{
    bottom: $bottom;
  }
  @if $left != null{
    left: $left;
  }
}

@mixin aspect-ratio($width, $height, $image_path: null) {
  display: block;
  width: 100%;
  height: 0;
  padding-top: ($height / $width) * 100%;
  @if $image_path != null {
    background: url($image_path) 50% 50% no-repeat;
    background-size: cover;
  }
}

$mq-breakpoints: (
  'xsmall': 320px,
  'small' : 600px,
  'medium': 769px,
  'large' : 1024px,
  'xlarge': 1366px,
);

@mixin mq($breakpoint: medium, $rule: min, $subtraction: false) {
  $breakpoint: map_get($mq-breakpoints, $breakpoint);
  @if $rule == max and $subtraction {
    $breakpoint: $breakpoint - 1;
  }
  @media screen and (#{$rule}-width: $breakpoint) {
    @content;
  }
}

@mixin mqsp($breakpoint: medium, $rule: max, $subtraction: true) {
  $breakpoint: map_get($mq-breakpoints, $breakpoint);
  @if $rule == max and $subtraction {
    $breakpoint: $breakpoint - 1;
  }
  @media screen and (#{$rule}-width: $breakpoint) {
    @content;
  }
}

@mixin fontsize($size: 24, $base: 16) {
  font-size: $size + px;
  font-size: ($size / $base) * 1rem;
}

@mixin clearfix() {
  &::after {
    display: block;
    content: "";
    clear: both;
  }
}

@mixin border-radius($radius) {
          border-radius: $radius;
}
@mixin border-top-left-radius($radius) {
          border-top-left-radius: $radius;
}
@mixin border-top-right-radius($radius) {
          border-top-right-radius: $radius;
}
@mixin border-bottom-right-radius($radius) {
          border-bottom-right-radius: $radius;
}
@mixin border-bottom-left-radius($radius) {
          border-bottom-left-radius: $radius;
}

@function px-per($px, $parent_width:750){
  @return decimal-round( percentage( $px / $parent_width ) , 3);
}

@function decimal-round ($number, $digits: 0, $mode: round) {
      $n: 1;
      // $number must be a number
      @if type-of($number) != number {
            @warn '#{ $number } is not a number.';
            @return $number;
      }
      // $digits must be a unitless number
      @if type-of($digits) != number {
            @warn '#{ $digits } is not a number.';
            @return $number;
      } @else if not unitless($digits) {
            @warn '#{ $digits } has a unit.';
            @return $number;
      }
      @for $i from 1 through $digits {
            $n: $n * 10;
      }
      @if $mode == round {
            @return round($number * $n) / $n;
      } @else if $mode == ceil {
            @return ceil($number * $n) / $n;
      } @else if $mode == floor {
            @return floor($number * $n) / $n;
      } @else {
            @warn '#{ $mode } is undefined keyword.';
            @return $number;
      }
}

@mixin middle-line($height){
    height: $height + px;
    line-height: $height + px;
}

@mixin full-width(){
  margin-left: calc((100vw - 100%) / -2);
  margin-right: calc((100vw - 100%) / -2);
  max-width: auto;
  max-width: initial;
}

@function get_vw($size, $viewport:375){
  $rate: 100 / $viewport;
  @return $rate * $size * 1vw;
}

@mixin fz-vw($font_size:10){
  font-size: $font_size * 1px;
  font-size: get_vw($font_size);
}

@function px-vw($pxValue) {
$spviewport: 750;
 @return decimal-round(( $pxValue / $spviewport ) * 100, 3) + vw;
}

@function decimal-round ($number, $digits: 0, $mode: round) {
      $n: 1;
      // $number must be a number
      @if type-of($number) != number {
            @warn '#{ $number } is not a number.';
            @return $number;
      }
      // $digits must be a unitless number
      @if type-of($digits) != number {
            @warn '#{ $digits } is not a number.';
            @return $number;
      } @else if not unitless($digits) {
            @warn '#{ $digits } has a unit.';
            @return $number;
      }
      @for $i from 1 through $digits {
            $n: $n * 10;
      }
      @if $mode == round {
            @return round($number * $n) / $n;
      } @else if $mode == ceil {
            @return ceil($number * $n) / $n;
      } @else if $mode == floor {
            @return floor($number * $n) / $n;
      } @else {
            @warn '#{ $mode } is undefined keyword.';
            @return $number;
      }
}

@mixin placeholderColor($color) {
    &:placeholder-shown {
        color: $color;
    }
    &::-webkit-input-placeholder {
        color:$color;
    }
    &:-moz-placeholder {
        color:$color;
        opacity: 1;
    }
    &::-moz-placeholder {
        color:$color;
        opacity: 1;
    }
    &:-ms-input-placeholder {
        color:$color;
    }
}

// IEハック用mixin
@mixin hack($IE-ver: null) {
  @if $IE-ver == 'IE8' { // IE8以下
    @media \0screen\,screen\9 {
      @content;
    }
  }
  @else if $IE-ver == 'IE9' { // IE9以上
    @media screen and (min-width:0\0) {
      @content;
    }
  }
  @else if $IE-ver == 'IE10' { // IE10以上
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      @content;
    }
  }
  @else if $IE-ver == 'IE11' { // IE11以上
    @at-root _:-ms-fullscreen,:root & {
      @content;
    }
  }
  @else if $IE-ver == 'notIE8' { // IE8じゃないとき(他のブラウザも含める)
    @media all and (min-width: 0) {
      @content;
    }
  }
}

@mixin stripes($direction, $colors) {
  $length: length($colors);

  @if $length > 1 {
    $stripes: ();

    @for $i from 1 through $length {
      $stripe: (100% / $length) * ($i - 1);

      @if $i > 1 {
        $previous-color: nth($colors, $i - 1);
        $stripes: append($stripes, $previous-color $stripe, comma);
      }

      $stripes: append($stripes, nth($colors, $i) $stripe, comma);
    }
    background: linear-gradient($direction, $stripes) fixed;
  } @else if $length == 1 {
    background-color: $colors;
  }
}
