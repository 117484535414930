@charset "utf-8";

.is-hidden {
  display: none;
}

.is-show {
  display: block;
}

.is-visible {
  opacity: 1;
}

.is-invisible {
  opacity: 0;
}

//共通クラス
/////////////////////////////

///*  col class */
//$split-grid: 12;
//
//.col-1 {
//  flex: 0 0 100% / $split-grid * 1;
//  width: 100% / $split-grid * 1;
//}
//
//.col-2 {
//  flex: 0 0 100% / $split-grid * 2;
//  width: 100% / $split-grid * 2;
//}
//
//.col-3 {
//  flex: 0 0 100% / $split-grid * 3;
//  width: 100% / $split-grid * 3;
//}
//
//.col-4 {
//  flex: 0 0 100% / $split-grid * 4;
//  width: 100% / $split-grid * 4;
//}
//
//.col-5 {
//  flex: 0 0 100% / $split-grid * 5;
//  width: 100% / $split-grid * 5;
//}
//
//.col-6 {
//  flex: 0 0 100% / $split-grid * 6;
//  width: 100% / $split-grid * 6;
//}
//
//.col-7 {
//  flex: 0 0 100% / $split-grid * 7;
//  width: 100% / $split-grid * 7;
//}
//
//.col-8 {
//  flex: 0 0 100% / $split-grid * 8;
//  width: 100% / $split-grid * 8;
//}
//
//.col-9 {
//  flex: 0 0 100% / $split-grid * 9;
//  width: 100% / $split-grid * 9;
//}
//
//.col-10 {
//  flex: 0 0 100% / $split-grid * 10;
//  width: 100% / $split-grid * 10;
//}
//
//.col-11 {
//  flex: 0 0 100% / $split-grid * 11;
//  width: 100% / $split-grid * 11;
//}
//
//.col-12 {
//  flex: 0 0 100% / $split-grid * 12;
//  width: 100% / $split-grid * 12;
//}
//
//@include mq() {
//  $split-grid: 12;
//
//  .col-pc-1 {
//    flex: 0 0 100% / $split-grid * 1;
//    width: 100% / $split-grid * 1;
//  }
//
//  .col-pc-2 {
//    flex: 0 0 100% / $split-grid * 2;
//    width: 100% / $split-grid * 2;
//  }
//
//  .col-pc-3 {
//    flex: 0 0 100% / $split-grid * 3;
//    width: 100% / $split-grid * 3;
//  }
//
//  .col-pc-4 {
//    flex: 0 0 100% / $split-grid * 4;
//    width: 100% / $split-grid * 4;
//  }
//
//  .col-pc-5 {
//    flex: 0 0 100% / $split-grid * 5;
//    width: 100% / $split-grid * 5;
//  }
//
//  .col-pc-6 {
//    flex: 0 0 100% / $split-grid * 6;
//    width: 100% / $split-grid * 6;
//  }
//
//  .col-pc-7 {
//    flex: 0 0 100% / $split-grid * 7;
//    width: 100% / $split-grid * 7;
//  }
//
//  .col-pc-8 {
//    flex: 0 0 100% / $split-grid * 8;
//    width: 100% / $split-grid * 8;
//  }
//
//  .col-pc-9 {
//    flex: 0 0 100% / $split-grid * 9;
//    width: 100% / $split-grid * 9;
//  }
//
//  .col-pc-10 {
//    flex: 0 0 100% / $split-grid * 10;
//    width: 100% / $split-grid * 10;
//  }
//
//  .col-pc-11 {
//    flex: 0 0 100% / $split-grid * 11;
//    width: 100% / $split-grid * 11;
//  }
//
//  .col-pc-12 {
//    flex: 0 0 100% / $split-grid * 12;
//    width: 100% / $split-grid * 12;
//  }
//}
//
//@include mqsp() {
//  $split-grid: 12;
//
//  .col-sp-1 {
//    flex: 0 0 100% / $split-grid * 1;
//    width: 100% / $split-grid * 1;
//  }
//
//  .col-sp-2 {
//    flex: 0 0 100% / $split-grid * 2;
//    width: 100% / $split-grid * 2;
//  }
//
//  .col-sp-3 {
//    flex: 0 0 100% / $split-grid * 3;
//    width: 100% / $split-grid * 3;
//  }
//
//  .col-sp-4 {
//    flex: 0 0 100% / $split-grid * 4;
//    width: 100% / $split-grid * 4;
//  }
//
//  .col-sp-5 {
//    flex: 0 0 100% / $split-grid * 5;
//    width: 100% / $split-grid * 5;
//  }
//
//  .col-sp-6 {
//    flex: 0 0 100% / $split-grid * 6;
//    width: 100% / $split-grid * 6;
//  }
//
//  .col-sp-7 {
//    flex: 0 0 100% / $split-grid * 7;
//    width: 100% / $split-grid * 7;
//  }
//
//  .col-sp-8 {
//    flex: 0 0 100% / $split-grid * 8;
//    width: 100% / $split-grid * 8;
//  }
//
//  .col-sp-9 {
//    flex: 0 0 100% / $split-grid * 9;
//    width: 100% / $split-grid * 9;
//  }
//
//  .col-sp-10 {
//    flex: 0 0 100% / $split-grid * 10;
//    width: 100% / $split-grid * 10;
//  }
//
//  .col-sp-11 {
//    flex: 0 0 100% / $split-grid * 11;
//    width: 100% / $split-grid * 11;
//  }
//
//  .col-sp-12 {
//    flex: 0 0 100% / $split-grid * 12;
//    width: 100% / $split-grid * 12;
//  }
//}
//
///*  margin and padding class */
//
//$em: 1;
//
//@for $i from 0 through 10 {
//  .mt#{$i * $em}em {
//    margin-top: #{$i * $em}em !important;
//  }
//
//  .mr#{$i * $em}em {
//    margin-right: #{$i * $em}em !important;
//  }
//
//  .mb#{$i * $em}em {
//    margin-bottom: #{$i * $em}em !important;
//  }
//
//  .ml#{$i * $em}em {
//    margin-left: #{$i * $em}em !important;
//  }
//
//  .pt#{$i * $em}em {
//    padding-top: #{$i * $em}em !important;
//  }
//
//  .pr#{$i * $em}em {
//    padding-right: #{$i * $em}em !important;
//  }
//
//  .pb#{$i * $em}em {
//    padding-bottom: #{$i * $em}em !important;
//  }
//
//  .pl#{$i * $em}em {
//    padding-left: #{$i * $em}em !important;
//  }
//}
//
//$num: 5;
//
//@for $i from 0 through 20 {
//  .mt#{$i * $num} {
//    margin-top: #{$i * $num}px !important;
//  }
//
//  .mr#{$i * $num} {
//    margin-right: #{$i * $num}px !important;
//  }
//
//  .mb#{$i * $num} {
//    margin-bottom: #{$i * $num}px !important;
//  }
//
//  .ml#{$i * $num} {
//    margin-left: #{$i * $num}px !important;
//  }
//
//  .pt#{$i * $num} {
//    padding-top: #{$i * $num}px !important;
//  }
//
//  .pr#{$i * $num} {
//    padding-right: #{$i * $num}px !important;
//  }
//
//  .pb#{$i * $num} {
//    padding-bottom: #{$i * $num}px !important;
//  }
//
//  .pl#{$i * $num} {
//    padding-left: #{$i * $num}px !important;
//  }
//
//  @include mq() {
//    .pc-mt#{$i * $num} {
//      margin-top: #{$i * $num}px !important;
//    }
//
//    .pc-mr#{$i * $num} {
//      margin-right: #{$i * $num}px !important;
//    }
//
//    .pc-mb#{$i * $num} {
//      margin-bottom: #{$i * $num}px !important;
//    }
//
//    .pc-ml#{$i * $num} {
//      margin-left: #{$i * $num}px !important;
//    }
//
//    .pc-pt#{$i * $num} {
//      padding-top: #{$i * $num}px !important;
//    }
//
//    .pc-pr#{$i * $num} {
//      padding-right: #{$i * $num}px !important;
//    }
//
//    .pc-pb#{$i * $num} {
//      padding-bottom: #{$i * $num}px !important;
//    }
//
//    .pc-pl#{$i * $num} {
//      padding-left: #{$i * $num}px !important;
//    }
//  }
//
//  @include mqsp() {
//    .sp-mt#{$i * $num} {
//      margin-top: #{$i * $num}px !important;
//    }
//
//    .sp-mr#{$i * $num} {
//      margin-right: #{$i * $num}px !important;
//    }
//
//    .sp-mb#{$i * $num} {
//      margin-bottom: #{$i * $num}px !important;
//    }
//
//    .sp-ml#{$i * $num} {
//      margin-left: #{$i * $num}px !important;
//    }
//
//    .sp-pt#{$i * $num} {
//      padding-top: #{$i * $num}px !important;
//    }
//
//    .sp-pr#{$i * $num} {
//      padding-right: #{$i * $num}px !important;
//    }
//
//    .sp-pb#{$i * $num} {
//      padding-bottom: #{$i * $num}px !important;
//    }
//
//    .sp-pl#{$i * $num} {
//      padding-left: #{$i * $num}px !important;
//    }
//  }
//
//  .n-mt#{$i * $num} {
//    margin-top: -#{$i * $num}px !important;
//  }
//
//  .n-mr#{$i * $num} {
//    margin-right: -#{$i * $num}px !important;
//  }
//
//  .n-mb#{$i * $num} {
//    margin-bottom: -#{$i * $num}px !important;
//  }
//
//  .n-ml#{$i * $num} {
//    margin-left: -#{$i * $num}px !important;
//  }
//
//  @include mq() {
//    .pc-n-mt#{$i * $num} {
//      margin-top: -#{$i * $num}px !important;
//    }
//
//    .pc-n-mr#{$i * $num} {
//      margin-right: -#{$i * $num}px !important;
//    }
//
//    .pc-n-mb#{$i * $num} {
//      margin-bottom: -#{$i * $num}px !important;
//    }
//
//    .pc-n-ml#{$i * $num} {
//      margin-left: -#{$i * $num}px !important;
//    }
//  }
//
//  @include mqsp() {
//    .sp-n-mt#{$i * $num} {
//      margin-top: -#{$i * $num}px !important;
//    }
//
//    .sp-n-mr#{$i * $num} {
//      margin-right: -#{$i * $num}px !important;
//    }
//
//    .sp-n-mb#{$i * $num} {
//      margin-bottom: -#{$i * $num}px !important;
//    }
//
//    .sp-n-ml#{$i * $num} {
//      margin-left: -#{$i * $num}px !important;
//    }
//  }
//}

/*  font and text class */

.italic {
  font-style: italic;
}

.small-caps {
  font-variant: small-caps;
}

.bold {
  font-weight: bold;
}

.xx-small {
  font-size: 60%;
}

.x-small {
  font-size: 75%;
}

.small {
  font-size: 88.88%;
}

.large {
  font-size: 120%;
}

.x-large {
  font-size: 150%;
}

.xx-large {
  font-size: 200%;
}

.lh-100 {
  line-height: 1 !important;
}

.lh-125 {
  line-height: 1.25 !important;
}

.lh-150 {
  line-height: 1.50 !important;
}

.lh-175 {
  line-height: 1.75 !important;
}

.overline {
  text-decoration: overline;
}

.underline {
  text-decoration: underline;
}

.capitalize {
  text-transform: capitalize;
}

.uppercase {
  text-transform: uppercase;
}

.lowercase {
  text-transform: lowercase;
}

/*  layout class */
.indent {
  text-indent: 1em;
}

.indent-back {
  padding-left: 1em;
  text-indent: -1em;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.float-left {
  float: left;
}

.float-right {
  float: right;
}

.wbr {
  display: inline-block;
  white-space: nowrap;
}


.serif {
  font-family: $serif;
}

.sans-serif {
  font-family: $sans-serif;
}

.sans-serif-rounded {
  font-family: $sans-serif-rounded;
}

.clearfix {
  &::after {
    content: "";
    display: block;
    clear: both;
  }
}

.d-flex {
  display: flex;
}

.d-inline-flex {
  display: inline-flex;
}

.align-items-center {
  align-items: center;
}

.jc-space-between {
  justify-content: space-between;
}

.jc-center {
  justify-content: center;
}

.row-reverse {
  flex-direction: row-reverse;
}

.pointer {
  cursor: pointer;
}

.d-block {
  display: block;
}

.d-inline {
  display: inline;
}

.d-inline-block {
  display: inline-block;
}

.circle {
  border-radius: 50%;
}


//PCのみ
//////////////////////////////
@include mq() {
  .pc-inline {
    display: inline !important;
  }

  .pc-inline-block {
    display: inline-block !important;
  }

  .pc-block {
    display: block !important;
  }

  .pc-hidden {
    display: none !important;
  }

  .pc-flex {
    display: flex !important;
  }

  .pc-inline-flex {
    display: inline-flex !important;
  }
}

//SPのみ
/////////////////////////////
@include mqsp() {
  .sp-inline {
    display: inline !important;
  }

  .sp-inline-block {
    display: inline-block !important;
  }

  .sp-block {
    display: block !important;
  }

  .sp-hidden {
    display: none !important;
  }

  .sp-flex {
    display: flex !important;
  }

  .sp-inline-flex {
    display: inline-flex !important;
  }
}
