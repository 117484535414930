@charset "utf-8";

.footer {
    position: relative;
    .page-top {
        position: absolute;
        top: -.55rem;
        right: .3rem;
        width: 1.76rem;
        @include mq() {
            top: -65px;
            right: 20px;
            width: auto;
        }
    }
}

.foonter-inner {
    padding: .6rem 0;
    font-size: .24rem;
    letter-spacing: .02em;
    text-align: center;
    @include mq() {
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
        margin: auto;
        padding: 65px 20px 50px;
        max-width: 1120px;
        font-size: 12px;
    }
}

.footer-link {
    margin-bottom: .6rem;
    @include mq() {
        margin-bottom: 0;
        font-size: 16px;
    }
}
