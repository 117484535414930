@charset "utf-8";

////共通パーツ////
////////////////////////////////

.container {
    padding: 0 .3rem;
    @include mq() {
        margin: auto;
        padding: 0 20px;
        max-width: 1120px;
    }
}

.container-narrow {
    padding: 0 .6rem;
    @include mq() {
        margin: auto;
        padding: 0 20px;
        max-width: 1000px;
    }
}

.section-ttl {
    font-size: .48rem;
    font-weight: 700;
    font-family: 'Montserrat', sans-serif;
    letter-spacing: .06em;
    text-align: center;
    @include mq() {
        font-size: 36px;
    }
}

////VISION////
////////////////////////////////

.vision {
    margin-top: .8rem;
    @include mq() {
        margin-top: 90px;
    }
}

.vision-ttl {
    font-weight: 700;
    text-align: center;
    .ja {
        padding-left: .5em;
        font-size: .678rem;
        letter-spacing: -0.02em;
        @include mq() {
            font-size: 50px;
        }
    }
    .en {
        display: block;
        margin-top: .3rem;
        font-size: .2712rem;
        letter-spacing: .08em;
        @include mq() {
            margin-top: 20px;
            font-size: 20px;
        }
    }
}

.vision-media {
    margin-top: .8rem;
    @include mq() {
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        margin: auto;
        padding-top: 55px;
        max-width: 1080px;
        > * {
            width: 50%;
        }
    }
    .movie {
        @include mq() {
            padding-left: 30px;
        }
    }
    .movie-inner{
        overflow: hidden;
        position: relative;
        width: 100%;
        padding-top: 9 / 16 * 100%;
        .Edge & , .Ie & {
            padding-top: 52%;
        }
        iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }
    .text {
        @include mq() {
            display: flex;
            justify-content: center;
        }
    }
    p {
        padding-top: .6rem;
        text-align: center;
        letter-spacing: .06em;
        line-height: 2.15;
        @include mq() {
            padding: 0;
            text-align: left;
        }
    }
}

////NEWS////
////////////////////////////////

.news {
    margin-top: 1.55rem;
    @include mq() {
        margin-top: 130px;
    }
}

.news-list {
    margin-top: .6rem;
    line-height: 1.55;
    @include mq() {
        margin-top: 45px;
    }
    li {
        padding: .35rem 0 .45rem;
        border-bottom: 1px #ececec solid;
        @include mq() {
            display: flex;
            align-items: baseline;
            padding: 30px 50px 30px 0;
        }
        &:first-child {
            border-top: 1px #ececec solid;
        }
    }
    .date {
        margin-bottom: .20rem;
        font-size: .28rem;
        letter-spacing: .06em;
        font-family: 'Staatliches', cursive;
        @include mq() {
            margin-right: 70px;
            font-size: 18px;
        }
    }
    p {
        text-align: justify;
    }
}

////SERVICE////
////////////////////////////////

.service {
    margin-top: 1.5rem;
    @include mq() {
        margin-top: 160px;
    }
}

////SERVICE 共通////
////////////////////////////////


.service-subttl {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: .3rem;
    letter-spacing: .06em;
    @include mq() {
        font-size: 18px;
    }
    span {
        padding: 0 1em;
    }
    &::before,&::after {
        content: '';
        display: block;
        width: .2rem;
        height: .02rem;
        background: #000;
        @include mq() {
            width: 15px;
            height: 1px;
        }
    }
}

.service-sns-btn {
    display: flex;
    justify-content: space-between;
    li {
        width: 2.16rem;
        @include mq() {
            width: 160 / 510 * 100%;
        }
    }
}

////SERVICE ワイン事業////
////////////////////////////////

.service-wine {
    margin-top: .65rem;
    @include mq() {
        margin-top: 55px;
    }
}

.service-wine-mainvisual {
    margin-top: .4rem;
    @include mq() {
        margin-top: 35px;
    }
}

.service-wine-media {
    margin-top: .6rem;
    @include mq() {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 55px;
    }
}

.service-wine-box {
    @include mq() {
        max-width: 50%;
        &:first-child {
            width: 50%;
        }
    }
    .logo {
        margin: auto;
        width: 1.34rem;
        @include mq() {
            width: auto;
            text-align: center;
        }
    }
    h4 {
        margin-top: .4rem;
        font-size: .42rem;
        font-weight: 400;
        font-family: 'Montserrat', sans-serif;
        letter-spacing: .06em;
        text-align: center;
        @include mq() {
            margin-top: 15px;
            font-size: 41px;
        }
    }
    p {
        margin-top: .35rem;
        line-height: 1.65;
        letter-spacing: .06em;
        text-align: center;
        @include mq() {
            margin-top: 25px;
            font-size: 18px;
            line-height: 1.5;
        }
    }
    .img {
        margin-top: .6rem;
        margin-bottom: .3rem;
        @include mq() {
            margin-top: 0;
            margin-bottom: 20px;
        }
    }
}

////SERVICE ワイン事業////
////////////////////////////////

.service-dining {
    margin-top: 1.45rem;
    @include mq() {
        margin-top: 95px;
    }
}

.service-dining-list {
    margin-top: .5rem;
    @include mq() {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-top: 40px;
    }
    > li {
        @include mq() {
            max-width: 510px;
            width: 49%;
            &:nth-child(-n + 2) {
                margin-bottom: 60px;
            }
        }
        + li {
            margin-top: .95rem;
            @include mq() {
                margin-top: 0;
            }
        }
    }
}

.service-dining-box {
    position: relative;
    overflow: hidden;
    margin-bottom: .28rem;
    @include mq() {
        margin-bottom: 20px;
    }
    &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        display: block;
        width: 100%;
        height: 100%;
        background: rgba(#000,.35);
    }
    h4 {
        position: absolute;
        top: 50%;
        left: 50%;
        z-index: 2;
        font-size: .36rem;
        font-weight: 700;
        letter-spacing: .06em;
        white-space: nowrap;
        color: #fff;
        transform: translate(-50%,-50%);
        @include mq() {
            font-size: 24px;
        }
    }
}

////MESSAGE////
////////////////////////////////

.message {
    margin-top: 1.95rem;
    @include mq() {
        margin-top: 145px;
    }
}

.message-media {
    margin-top: .6rem;
    @include mq() {
        display: flex;
        justify-content: center;
        align-items: center;
        max-width: 800px;
        margin: 35px auto 0;
    }
}

.message-media-box {
    @include mq() {
        &:first-child {
            flex-shrink: 0;
            padding-right: 50px;
            text-align: center;
        }
    }
    .img {
        margin: auto;
        width: 2.7rem;
        @include mq() {
            width: auto;
        }
    }
    .name {
        text-align: center;
        letter-spacing: .06em;
        white-space: nowrap;
        @include mq() {
            font-size: 14px;
        }
        .strong {
            margin-top: .25rem;
            margin-bottom: .2rem;
            font-size: .3rem;
            font-weight: 700;
            @include mq() {
                margin: 15px 0;
                font-size: 24px;
            }
        }
    }
    .text {
        margin-top: .55rem;
        line-height: 1.92;
        letter-spacing: .06em;
        text-align: justify;
        @include mq() {
            margin-top: 0;
            line-height: 2;
        }
    }
}

////ABOUT////
////////////////////////////////

.about {
    margin-top: 1.4rem;
    @include mq() {
        margin-top: 155px;
    }
}

.about-overview {
    margin-top: .8rem;
    @include mq() {
        margin-top: 55px;
    }
    dl {
        padding: .45rem 0 .5rem;
        border-bottom: 1px #ececec solid;
        @include mq() {
            display: flex;
            align-items: baseline;
            padding: 30px 0 35px;
        }
        &:first-child {
            border-top: 1px #ececec solid;
        }
    }
    dt {
        margin-bottom: .3rem;
        font-size: .3rem;
        font-weight: 700;
        letter-spacing: 0.13em;
        color: #333;
        @include mq() {
            width: 150px;
            font-size: 18px;
        }
    }
    dd {
        letter-spacing: .13em;
        @include mq() {
            flex: 1;
        }
    }
    p {
        + p{
            margin-top: .1rem;
            @include mq() {
                margin-top: 10px;
            }
        }
    }
    li {
        + li {
            margin-top: .1rem;
            @include mq() {
                margin-top: 10px;
            }
        }
    }
}

////RECRUIT////
////////////////////////////////

.recruit {
    margin-top: 1.5rem;
    padding: 1.05rem 0 1.3rem;
    background: url(../img/recruit_bg.jpg) no-repeat center center / cover;
    @include mq() {
        margin-top: 130px;
        padding: 110px 0;
        height: 422px;
        background: url(../img/pc/recruit_bg.jpg) no-repeat center center / cover;
    }
    .section-ttl {
        color: #fff;
    }
}

.recruit-btn {
    margin-top: .5rem;
    text-align: center;
    @include mq() {
        margin-top: 60px;
    }
    a {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: auto;
        width: 3.8rem;
        height: 1rem;
        font-size: .3rem;
        font-weight: 700;
        letter-spacing: .13em;
        color: $darkred;
        background: #fff;
        border-radius: .06rem;
        @include mq() {
            width: 264px;
            height: 70px;
            font-size: 20px;
            border-radius: 6px;
        }
    }
}

////CONTACT////
////////////////////////////////

.contact {
    padding: 1.35rem 0 1.7rem;
    background: #f4f4f4;
    @include mq() {
        padding: 130px 0 120px;
    }
}

.contact-form {
    padding-top: .4rem;
    @include mq() {
        margin: auto;
        padding-top: 30px;
        max-width: 725px;
    }
    form {
        margin-top: .75rem;
        @include mq() {
            margin-top: 50px;
        }
    }
    dl {
        @include mq() {
            display: flex;
            align-items: center;
            &:last-of-type {
                dt {
                    align-self: flex-start;
                    padding-top: 27px;
                }
            }
        }
        + dl {
            margin-top: .5rem;
            @include mq() {
                margin-top: 30px;
            }
        }
    }
    dt {
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        letter-spacing: .13em;
        font-weight: 700;
        @include mq() {
            width: 180 /725 * 100%;
            font-size: 14px;
        }
        span {
            margin-right: auto;
        }
        .required {
            font-size: .3rem;
            line-height: 0.80;
            color: $red;
            @include mq() {
                font-size: 20px;
                line-height: 1;
            }
        }
    }
    dd {
        position: relative;
        margin-top: .2rem;
        @include mq() {
            flex: 1;
            margin-top: 0;
        }
    }
    input[type="text"],input[type="email"],input[type="tel"] {
        padding: .2rem .2rem;
        width: 100%;
        font-size: .3rem;
        letter-spacing: .13em;
        border: .03rem #eee solid;
        border-radius: .06rem;
        @include mq() {
            padding: 20px;
            font-size: 16px;
            border-width: 3px;
            border-radius: 6px;
        }
    }
    textarea {
        padding: .2rem;
        width: 100%;
        height: .3*8*1.5+.4rem;
        font-size: .30rem;
        letter-spacing: .1em;
        line-height: 1.5;
        border: .03rem #eee solid;
        border-radius: .06rem;
        @include mq() {
            padding: 20px;
            height: 16*1.5*9+40px;
            font-size: 16px;
            border-width: 3px;
            border-radius: 6px;
        }
    }
    .error_blank,.error_format {
        position: absolute;
        top: 100%;
        left: 0;
        display: block;
        margin-top: .1rem;
        font-size: .24rem;
        color: $red;
        @include mq() {
            margin-top: 5px;
            font-size: 14px;
        }
    }
    .btn-wrap {
        margin-top: .9rem;
        @include mq() {
            margin-top: 40px;
        }
    }
    .btn {
        display: block;
        margin: auto;
        width: 3.8rem;
        height: 1rem;
        font-size: .3rem;
        letter-spacing: .13em;
        font-weight: 700;
        color: #fff;
        background: $darkred;
        border-radius: .06rem;
        @include mq() {
            width: 264px;
            height: 70px;
            font-size: 20px;
            border-radius: 6px;
        }
    }
}

.contact-form-note {
    letter-spacing: .13em;
    text-align: center;
    color: $red;
}

.thanks {
    padding: .8rem 0 .5rem;
    @include mq() {
        padding: 100px 0 100px;
    }
    p {
        font-size: .32rem;
        line-height: 2;
        text-align: center;
        @include mq() {
            font-size: 24px;
            line-height: 2.5;
        }
    }
    .btn {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: .3rem auto 0 ;
        width: 3.8rem;
        height: 1rem;
        font-size: .3rem;
        letter-spacing: .13em;
        font-weight: 700;
        color: #fff;
        background: $darkred;
        border-radius: .06rem;
        @include mq() {
            margin: 25px auto 0;
            width: 264px;
            height: 70px;
            font-size: 20px;
            border-radius: 6px;
        }
    }
}
