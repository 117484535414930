@charset "UTF-8";
/*
html5doctor.com Reset Stylesheet
v1.6.1
Last Updated: 2010-09-17
Author: Richard Clark - http://richclarkdesign.com
Twitter: @rich_clark
*/
@import url("https://fonts.googleapis.com/css?family=Montserrat:400,700|Staatliches&display=swap");
html, body, div, span, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, abbr, address, cite, code, del, dfn, em, img, ins, kbd, q, samp, small, strong, sub, sup, var, b, i, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, figcaption, figure, footer, header, hgroup, menu, nav, section, summary, time, mark, audio, video { margin: 0; padding: 0; border: 0; outline: 0; line-height: 1; font: inherit; font-size: 100%; vertical-align: baseline; background: transparent; }

body { line-height: 1; }

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section, main { display: block; }

nav ul { list-style: none; }

blockquote, q { quotes: none; }

blockquote:before, blockquote:after, q:before, q:after { content: ''; content: none; }

a { margin: 0; padding: 0; font-size: 100%; vertical-align: baseline; background: transparent; }

/* change colours to suit your needs */
ins { background-color: #ff9; color: #000; text-decoration: none; }

/* change colours to suit your needs */
mark { background-color: #ff9; color: #000; font-style: italic; font-weight: bold; }

del { text-decoration: line-through; }

abbr[title], dfn[title] { border-bottom: 1px dotted; cursor: help; }

table { border-collapse: collapse; border-spacing: 0; }

/* change border colour to suit your needs */
hr { display: block; height: 1px; border: 0; border-top: 1px solid #cccccc; margin: 1em 0; padding: 0; }

input, select { vertical-align: middle; }

input[type="submit"], input[type="button"] { border-radius: 0; -webkit-box-sizing: content-box; -webkit-appearance: button; appearance: button; border: none; box-sizing: border-box; }

input[type="submit"]::-webkit-search-decoration, input[type="button"]::-webkit-search-decoration { display: none; }

input[type="submit"]::focus, input[type="button"]::focus { outline-offset: -2px; }

/*
basestyle
*/
*, *::before, *::after { box-sizing: border-box; }

html { min-height: 100%; }

@media screen and (max-width: 768px) { html { font-size: 13.333vw; } }

body { height: 100%; min-width: 100%; color: #000; font-family: -apple-system, BlinkMacSystemFont, "游ゴシック Medium", YuGothic, "Yu Gothic Medium", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ ProN W3", "メイリオ", sans-serif; font-size: 0.26rem; font-weight: 400; -webkit-text-size-adjust: 100%; /* フォントレンダリング設定: 1x解像度ではsubpixel、2x以上の解像度ではgrayscale */ -webkit-font-smoothing: subpixel-antialiased; -moz-osx-font-smoothing: unset; }

@media screen and (min-width: 769px) { body { font-size: 16px; } }

@media all and (-ms-high-contrast: none) { body { font-family: -apple-system, BlinkMacSystemFont, "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ ProN W3", "メイリオ", sans-serif; } }

@media only screen and (-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx) { body { -webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: grayscale; } }

a { color: #000; text-decoration: none; }

img { border: none; vertical-align: top; max-width: 100%; height: auto; }

svg { max-width: 1000px; }

address { font-style: normal; }

ul { list-style-type: none; }

.is-hidden { display: none; }

.is-show { display: block; }

.is-visible { opacity: 1; }

.is-invisible { opacity: 0; }

/*  font and text class */
.italic { font-style: italic; }

.small-caps { font-variant: small-caps; }

.bold { font-weight: bold; }

.xx-small { font-size: 60%; }

.x-small { font-size: 75%; }

.small { font-size: 88.88%; }

.large { font-size: 120%; }

.x-large { font-size: 150%; }

.xx-large { font-size: 200%; }

.lh-100 { line-height: 1 !important; }

.lh-125 { line-height: 1.25 !important; }

.lh-150 { line-height: 1.50 !important; }

.lh-175 { line-height: 1.75 !important; }

.overline { text-decoration: overline; }

.underline { text-decoration: underline; }

.capitalize { text-transform: capitalize; }

.uppercase { text-transform: uppercase; }

.lowercase { text-transform: lowercase; }

/*  layout class */
.indent { text-indent: 1em; }

.indent-back { padding-left: 1em; text-indent: -1em; }

.text-left { text-align: left; }

.text-right { text-align: right; }

.text-center { text-align: center; }

.float-left { float: left; }

.float-right { float: right; }

.wbr { display: inline-block; white-space: nowrap; }

.serif { font-family: "Noto Serif JP", 游明朝, "Yu Mincho", YuMincho, "Hiragino Mincho ProN", HGS明朝E, メイリオ, Meiryo, serif; }

.sans-serif { font-family: -apple-system, BlinkMacSystemFont, "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ ProN W3", "メイリオ", sans-serif; }

.sans-serif-rounded { font-family: "Lucida Grande", "segoe UI", "ヒラギノ丸ゴ Pro W4", "ヒラギノ丸ゴ Pro", "Hiragino Maru Gothic Pro", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "HG丸ｺﾞｼｯｸM-PRO", "HGMaruGothicMPRO", sans-serif; }

.clearfix::after { content: ""; display: block; clear: both; }

.d-flex { display: flex; }

.d-inline-flex { display: inline-flex; }

.align-items-center { align-items: center; }

.jc-space-between { justify-content: space-between; }

.jc-center { justify-content: center; }

.row-reverse { flex-direction: row-reverse; }

.pointer { cursor: pointer; }

.d-block { display: block; }

.d-inline { display: inline; }

.d-inline-block { display: inline-block; }

.circle { border-radius: 50%; }

@media screen and (min-width: 769px) { .pc-inline { display: inline !important; }
  .pc-inline-block { display: inline-block !important; }
  .pc-block { display: block !important; }
  .pc-hidden { display: none !important; }
  .pc-flex { display: flex !important; }
  .pc-inline-flex { display: inline-flex !important; } }

@media screen and (max-width: 768px) { .sp-inline { display: inline !important; }
  .sp-inline-block { display: inline-block !important; }
  .sp-block { display: block !important; }
  .sp-hidden { display: none !important; }
  .sp-flex { display: flex !important; }
  .sp-inline-flex { display: inline-flex !important; } }

@media screen and (min-width: 769px) { .header { display: flex; flex-direction: column-reverse; } }

.header-inner { position: relative; padding: .17rem 0 .24rem; }

@media screen and (min-width: 769px) { .header-inner { display: flex; margin: auto; padding: 0 0 0 30px; max-width: 1200px; width: 100%; } }

.header-logo { margin: auto; width: 1.25rem; }

@media screen and (min-width: 769px) { .header-logo { flex-shrink: 0; position: relative; margin: 0; width: auto; }
  .header-logo h1 { position: relative; z-index: 2; margin-top: -10px; }
  .header-logo::before { content: ''; display: block; position: absolute; z-index: 1; bottom: 100%; left: 50%; width: 0; height: 0; border-style: solid; border-width: 0 105px 64px 105px; border-color: transparent transparent #ffffff transparent; transform: translateX(-50%); } }

.header-nav-spMenuTrigger { position: absolute; top: 50%; right: .3rem; z-index: 2; width: .44rem; height: .38rem; overflow: hidden; transform: translateY(-50%); }

@media screen and (min-width: 769px) { .header-nav-spMenuTrigger { display: none; } }

.header-nav-spMenuTrigger span { position: absolute; display: block; width: 100%; height: .04rem; background: #831a28; transition: .6s; }

.header-nav-spMenuTrigger span:nth-child(1) { top: 0; }

.header-nav-spMenuTrigger span:nth-child(2) { top: 50%; transform: translate(0, -50%); }

.header-nav-spMenuTrigger span:nth-child(3) { bottom: 0; }

.header-nav-spMenuTrigger.active span:nth-child(1) { top: 50%; transform: translateY(-50%) rotate(225deg); }

.header-nav-spMenuTrigger.active span:nth-child(2) { transform: translate(100%, -50%); opacity: 0; }

.header-nav-spMenuTrigger.active span:nth-child(3) { top: 50%; transform: translateY(-50%) rotate(-225deg); }

@media screen and (min-width: 769px) { .header-nav { max-width: 730px; width: 100%; margin: auto; } }

.header-nav-list { display: none; position: absolute; top: 100%; left: 0; z-index: 10; padding: .9rem 0 1.1rem .6rem; width: 100%; background: #831a28; }

@media screen and (min-width: 769px) { .header-nav-list { position: static; display: flex !important; justify-content: space-between; padding: 50px 0 30px; background: transparent; } }

.header-nav-list li { transform: translateX(-20%); opacity: 0; transition: .4s; }

@media screen and (min-width: 769px) { .header-nav-list li { margin: 0 5px; opacity: 1; transform: translateX(0); } }

.header-nav-list li + li { margin-top: .48rem; }

@media screen and (min-width: 769px) { .header-nav-list li + li { margin-top: 0; } }

.header-nav-list.active li { opacity: 1; transform: translateX(0); }

.header-nav-list.active li:nth-child(1) { transition-delay: 0s; }

.header-nav-list.active li:nth-child(2) { transition-delay: 0.15s; }

.header-nav-list.active li:nth-child(3) { transition-delay: 0.3s; }

.header-nav-list.active li:nth-child(4) { transition-delay: 0.45s; }

.header-nav-list.active li:nth-child(5) { transition-delay: 0.6s; }

.header-nav-list.active li:nth-child(6) { transition-delay: 0.75s; }

.header-nav-list.active li:nth-child(7) { transition-delay: 0.9s; }

.header-nav-list a { font-size: .48rem; letter-spacing: .06em; font-weight: 700; font-family: 'Montserrat', sans-serif; color: #fff; }

@media screen and (min-width: 769px) { .header-nav-list a { font-size: 15px; color: #000; } }

@media screen and (min-width: 769px) { .header-mainvisual { height: 540px; background: url(../img/pc/main_visual.jpg) no-repeat center center; } }

.footer { position: relative; }

.footer .page-top { position: absolute; top: -.55rem; right: .3rem; width: 1.76rem; }

@media screen and (min-width: 769px) { .footer .page-top { top: -65px; right: 20px; width: auto; } }

.foonter-inner { padding: .6rem 0; font-size: .24rem; letter-spacing: .02em; text-align: center; }

@media screen and (min-width: 769px) { .foonter-inner { display: flex; flex-direction: row-reverse; justify-content: space-between; margin: auto; padding: 65px 20px 50px; max-width: 1120px; font-size: 12px; } }

.footer-link { margin-bottom: .6rem; }

@media screen and (min-width: 769px) { .footer-link { margin-bottom: 0; font-size: 16px; } }

.container { padding: 0 .3rem; }

@media screen and (min-width: 769px) { .container { margin: auto; padding: 0 20px; max-width: 1120px; } }

.container-narrow { padding: 0 .6rem; }

@media screen and (min-width: 769px) { .container-narrow { margin: auto; padding: 0 20px; max-width: 1000px; } }

.section-ttl { font-size: .48rem; font-weight: 700; font-family: 'Montserrat', sans-serif; letter-spacing: .06em; text-align: center; }

@media screen and (min-width: 769px) { .section-ttl { font-size: 36px; } }

.vision { margin-top: .8rem; }

@media screen and (min-width: 769px) { .vision { margin-top: 90px; } }

.vision-ttl { font-weight: 700; text-align: center; }

.vision-ttl .ja { padding-left: .5em; font-size: .678rem; letter-spacing: -0.02em; }

@media screen and (min-width: 769px) { .vision-ttl .ja { font-size: 50px; } }

.vision-ttl .en { display: block; margin-top: .3rem; font-size: .2712rem; letter-spacing: .08em; }

@media screen and (min-width: 769px) { .vision-ttl .en { margin-top: 20px; font-size: 20px; } }

.vision-media { margin-top: .8rem; }

@media screen and (min-width: 769px) { .vision-media { display: flex; flex-direction: row-reverse; align-items: center; margin: auto; padding-top: 55px; max-width: 1080px; }
  .vision-media > * { width: 50%; } }

@media screen and (min-width: 769px) { .vision-media .movie { padding-left: 30px; } }

.vision-media .movie-inner { overflow: hidden; position: relative; width: 100%; padding-top: 56.25%; }

.Edge .vision-media .movie-inner, .Ie .vision-media .movie-inner { padding-top: 52%; }

.vision-media .movie-inner iframe { position: absolute; top: 0; left: 0; width: 100%; height: 100%; }

@media screen and (min-width: 769px) { .vision-media .text { display: flex; justify-content: center; } }

.vision-media p { padding-top: .6rem; text-align: center; letter-spacing: .06em; line-height: 2.15; }

@media screen and (min-width: 769px) { .vision-media p { padding: 0; text-align: left; } }

.news { margin-top: 1.55rem; }

@media screen and (min-width: 769px) { .news { margin-top: 130px; } }

.news-list { margin-top: .6rem; line-height: 1.55; }

@media screen and (min-width: 769px) { .news-list { margin-top: 45px; } }

.news-list li { padding: .35rem 0 .45rem; border-bottom: 1px #ececec solid; }

@media screen and (min-width: 769px) { .news-list li { display: flex; align-items: baseline; padding: 30px 50px 30px 0; } }

.news-list li:first-child { border-top: 1px #ececec solid; }

.news-list .date { margin-bottom: .20rem; font-size: .28rem; letter-spacing: .06em; font-family: 'Staatliches', cursive; }

@media screen and (min-width: 769px) { .news-list .date { margin-right: 70px; font-size: 18px; } }

.news-list p { text-align: justify; }

.service { margin-top: 1.5rem; }

@media screen and (min-width: 769px) { .service { margin-top: 160px; } }

.service-subttl { display: flex; justify-content: center; align-items: center; font-size: .3rem; letter-spacing: .06em; }

@media screen and (min-width: 769px) { .service-subttl { font-size: 18px; } }

.service-subttl span { padding: 0 1em; }

.service-subttl::before, .service-subttl::after { content: ''; display: block; width: .2rem; height: .02rem; background: #000; }

@media screen and (min-width: 769px) { .service-subttl::before, .service-subttl::after { width: 15px; height: 1px; } }

.service-sns-btn { display: flex; justify-content: space-between; }

.service-sns-btn li { width: 2.16rem; }

@media screen and (min-width: 769px) { .service-sns-btn li { width: 31.37255%; } }

.service-wine { margin-top: .65rem; }

@media screen and (min-width: 769px) { .service-wine { margin-top: 55px; } }

.service-wine-mainvisual { margin-top: .4rem; }

@media screen and (min-width: 769px) { .service-wine-mainvisual { margin-top: 35px; } }

.service-wine-media { margin-top: .6rem; }

@media screen and (min-width: 769px) { .service-wine-media { display: flex; align-items: center; justify-content: space-between; margin-top: 55px; } }

@media screen and (min-width: 769px) { .service-wine-box { max-width: 50%; }
  .service-wine-box:first-child { width: 50%; } }

.service-wine-box .logo { margin: auto; width: 1.34rem; }

@media screen and (min-width: 769px) { .service-wine-box .logo { width: auto; text-align: center; } }

.service-wine-box h4 { margin-top: .4rem; font-size: .42rem; font-weight: 400; font-family: 'Montserrat', sans-serif; letter-spacing: .06em; text-align: center; }

@media screen and (min-width: 769px) { .service-wine-box h4 { margin-top: 15px; font-size: 41px; } }

.service-wine-box p { margin-top: .35rem; line-height: 1.65; letter-spacing: .06em; text-align: center; }

@media screen and (min-width: 769px) { .service-wine-box p { margin-top: 25px; font-size: 18px; line-height: 1.5; } }

.service-wine-box .img { margin-top: .6rem; margin-bottom: .3rem; }

@media screen and (min-width: 769px) { .service-wine-box .img { margin-top: 0; margin-bottom: 20px; } }

.service-dining { margin-top: 1.45rem; }

@media screen and (min-width: 769px) { .service-dining { margin-top: 95px; } }

.service-dining-list { margin-top: .5rem; }

@media screen and (min-width: 769px) { .service-dining-list { display: flex; justify-content: space-between; flex-wrap: wrap; margin-top: 40px; } }

@media screen and (min-width: 769px) { .service-dining-list > li { max-width: 510px; width: 49%; }
  .service-dining-list > li:nth-child(-n + 2) { margin-bottom: 60px; } }

.service-dining-list > li + li { margin-top: .95rem; }

@media screen and (min-width: 769px) { .service-dining-list > li + li { margin-top: 0; } }

.service-dining-box { position: relative; overflow: hidden; margin-bottom: .28rem; }

@media screen and (min-width: 769px) { .service-dining-box { margin-bottom: 20px; } }

.service-dining-box::after { content: ''; position: absolute; top: 0; left: 0; z-index: 1; display: block; width: 100%; height: 100%; background: rgba(0, 0, 0, 0.35); }

.service-dining-box h4 { position: absolute; top: 50%; left: 50%; z-index: 2; font-size: .36rem; font-weight: 700; letter-spacing: .06em; white-space: nowrap; color: #fff; transform: translate(-50%, -50%); }

@media screen and (min-width: 769px) { .service-dining-box h4 { font-size: 24px; } }

.message { margin-top: 1.95rem; }

@media screen and (min-width: 769px) { .message { margin-top: 145px; } }

.message-media { margin-top: .6rem; }

@media screen and (min-width: 769px) { .message-media { display: flex; justify-content: center; align-items: center; max-width: 800px; margin: 35px auto 0; } }

@media screen and (min-width: 769px) { .message-media-box:first-child { flex-shrink: 0; padding-right: 50px; text-align: center; } }

.message-media-box .img { margin: auto; width: 2.7rem; }

@media screen and (min-width: 769px) { .message-media-box .img { width: auto; } }

.message-media-box .name { text-align: center; letter-spacing: .06em; white-space: nowrap; }

@media screen and (min-width: 769px) { .message-media-box .name { font-size: 14px; } }

.message-media-box .name .strong { margin-top: .25rem; margin-bottom: .2rem; font-size: .3rem; font-weight: 700; }

@media screen and (min-width: 769px) { .message-media-box .name .strong { margin: 15px 0; font-size: 24px; } }

.message-media-box .text { margin-top: .55rem; line-height: 1.92; letter-spacing: .06em; text-align: justify; }

@media screen and (min-width: 769px) { .message-media-box .text { margin-top: 0; line-height: 2; } }

.about { margin-top: 1.4rem; }

@media screen and (min-width: 769px) { .about { margin-top: 155px; } }

.about-overview { margin-top: .8rem; }

@media screen and (min-width: 769px) { .about-overview { margin-top: 55px; } }

.about-overview dl { padding: .45rem 0 .5rem; border-bottom: 1px #ececec solid; }

@media screen and (min-width: 769px) { .about-overview dl { display: flex; align-items: baseline; padding: 30px 0 35px; } }

.about-overview dl:first-child { border-top: 1px #ececec solid; }

.about-overview dt { margin-bottom: .3rem; font-size: .3rem; font-weight: 700; letter-spacing: 0.13em; color: #333; }

@media screen and (min-width: 769px) { .about-overview dt { width: 150px; font-size: 18px; } }

.about-overview dd { letter-spacing: .13em; }

@media screen and (min-width: 769px) { .about-overview dd { flex: 1; } }

.about-overview p + p { margin-top: .1rem; }

@media screen and (min-width: 769px) { .about-overview p + p { margin-top: 10px; } }

.about-overview li + li { margin-top: .1rem; }

@media screen and (min-width: 769px) { .about-overview li + li { margin-top: 10px; } }

.recruit { margin-top: 1.5rem; padding: 1.05rem 0 1.3rem; background: url(../img/recruit_bg.jpg) no-repeat center center/cover; }

@media screen and (min-width: 769px) { .recruit { margin-top: 130px; padding: 110px 0; height: 422px; background: url(../img/pc/recruit_bg.jpg) no-repeat center center/cover; } }

.recruit .section-ttl { color: #fff; }

.recruit-btn { margin-top: .5rem; text-align: center; }

@media screen and (min-width: 769px) { .recruit-btn { margin-top: 60px; } }

.recruit-btn a { display: flex; align-items: center; justify-content: center; margin: auto; width: 3.8rem; height: 1rem; font-size: .3rem; font-weight: 700; letter-spacing: .13em; color: #831a28; background: #fff; border-radius: .06rem; }

@media screen and (min-width: 769px) { .recruit-btn a { width: 264px; height: 70px; font-size: 20px; border-radius: 6px; } }

.contact { padding: 1.35rem 0 1.7rem; background: #f4f4f4; }

@media screen and (min-width: 769px) { .contact { padding: 130px 0 120px; } }

.contact-form { padding-top: .4rem; }

@media screen and (min-width: 769px) { .contact-form { margin: auto; padding-top: 30px; max-width: 725px; } }

.contact-form form { margin-top: .75rem; }

@media screen and (min-width: 769px) { .contact-form form { margin-top: 50px; } }

@media screen and (min-width: 769px) { .contact-form dl { display: flex; align-items: center; }
  .contact-form dl:last-of-type dt { align-self: flex-start; padding-top: 27px; } }

.contact-form dl + dl { margin-top: .5rem; }

@media screen and (min-width: 769px) { .contact-form dl + dl { margin-top: 30px; } }

.contact-form dt { display: flex; flex-direction: row-reverse; align-items: center; letter-spacing: .13em; font-weight: 700; }

@media screen and (min-width: 769px) { .contact-form dt { width: 24.82759%; font-size: 14px; } }

.contact-form dt span { margin-right: auto; }

.contact-form dt .required { font-size: .3rem; line-height: 0.80; color: #e22b19; }

@media screen and (min-width: 769px) { .contact-form dt .required { font-size: 20px; line-height: 1; } }

.contact-form dd { position: relative; margin-top: .2rem; }

@media screen and (min-width: 769px) { .contact-form dd { flex: 1; margin-top: 0; } }

.contact-form input[type="text"], .contact-form input[type="email"], .contact-form input[type="tel"] { padding: .2rem .2rem; width: 100%; font-size: .3rem; letter-spacing: .13em; border: .03rem #eee solid; border-radius: .06rem; }

@media screen and (min-width: 769px) { .contact-form input[type="text"], .contact-form input[type="email"], .contact-form input[type="tel"] { padding: 20px; font-size: 16px; border-width: 3px; border-radius: 6px; } }

.contact-form textarea { padding: .2rem; width: 100%; height: 4rem; font-size: .30rem; letter-spacing: .1em; line-height: 1.5; border: .03rem #eee solid; border-radius: .06rem; }

@media screen and (min-width: 769px) { .contact-form textarea { padding: 20px; height: 256px; font-size: 16px; border-width: 3px; border-radius: 6px; } }

.contact-form .error_blank, .contact-form .error_format { position: absolute; top: 100%; left: 0; display: block; margin-top: .1rem; font-size: .24rem; color: #e22b19; }

@media screen and (min-width: 769px) { .contact-form .error_blank, .contact-form .error_format { margin-top: 5px; font-size: 14px; } }

.contact-form .btn-wrap { margin-top: .9rem; }

@media screen and (min-width: 769px) { .contact-form .btn-wrap { margin-top: 40px; } }

.contact-form .btn { display: block; margin: auto; width: 3.8rem; height: 1rem; font-size: .3rem; letter-spacing: .13em; font-weight: 700; color: #fff; background: #831a28; border-radius: .06rem; }

@media screen and (min-width: 769px) { .contact-form .btn { width: 264px; height: 70px; font-size: 20px; border-radius: 6px; } }

.contact-form-note { letter-spacing: .13em; text-align: center; color: #e22b19; }

.thanks { padding: .8rem 0 .5rem; }

@media screen and (min-width: 769px) { .thanks { padding: 100px 0 100px; } }

.thanks p { font-size: .32rem; line-height: 2; text-align: center; }

@media screen and (min-width: 769px) { .thanks p { font-size: 24px; line-height: 2.5; } }

.thanks .btn { display: flex; align-items: center; justify-content: center; margin: .3rem auto 0; width: 3.8rem; height: 1rem; font-size: .3rem; letter-spacing: .13em; font-weight: 700; color: #fff; background: #831a28; border-radius: .06rem; }

@media screen and (min-width: 769px) { .thanks .btn { margin: 25px auto 0; width: 264px; height: 70px; font-size: 20px; border-radius: 6px; } }
